import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Veteran’s Day`}</em></p>
    <p>{`1/4 Depth Jump Squats 5×2\\@80%1RM`}</p>
    <p>{`Glute Ham Raise 5×2 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`Partner `}<strong parentName="em">{`“Alexander”`}</strong>{` (hero wod)`}</em></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`31-Back Squats (135/95)`}</p>
    <p>{`12-Power Cleans (185/125)`}</p>
    <p><em parentName="p">{`*`}{`share reps as needed`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      